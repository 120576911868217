import { PageProps } from 'gatsby';
import { ArticleData } from 'packages/innedit';
import React, { FC } from 'react';

import CMSView from '~/components/View';
import Form from '~/containers/Espace/Form';
import params from '~/params/article.json';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';

const PageArticlesUpdate: FC<PageProps & EspaceProps & UserProps> = ({
  espace,
  user,
  params: { articleId, espaceId },
}) => (
  <TemplateEspace
    collectionName="articles"
    docId={articleId}
    espace={espace}
    user={user}
  >
    <CMSView>
      <Form
        docId={articleId}
        itemPathnamePrefix={`/espaces/${espaceId}/articles/`}
        model={new ArticleData({ espaceId, params })}
        name="article"
        type="update"
      />
    </CMSView>
  </TemplateEspace>
);

export default requireEspace(PageArticlesUpdate);
